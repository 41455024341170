import React from 'react';
import style from './notfound.module.scss';

const NotFound = ({ link }) => {
    return <div className={style.notFound}>
        <h1>De pagina is niet gevonden</h1>
        {link != null
            ? <p>Ga terug naar {link}</p>
            : null}
    </div>
}

export default NotFound