import React from 'react'
import style from './login.module.scss';
import * as ApiRoutes from '../../api/apiRoutes';

class Login extends React.Component {
      
    render() {
        return <div className="col-12 col-sm-6 col-md-6 col-lg-4 offset-sm-3 offset-md-3 offset-lg-4">
            <div id="LoginPage" className={style.wrapper}>
                <div className={style.logoRow}>
                    <a href={ApiRoutes.LoginUser2}>
                        <img src={require("../../styling/img/OUADP-icon-lg.png")} alt="OU adapter logo" />
                    </a>
                </div>
                <form method="GET" action={ApiRoutes.LoginUser2} className={style.formRow}>                    
                    <div className={style.actionRow}>         
                        <input id="loginBtn" className={style.loginBtn} type="submit" value="Login"></input>
                    </div>
                </form>
            </div>
            {this.props.loginError != null
                ? <div className={style.validationRow}>
                    <span role="alert">
                        {this.props.loginError}
                    </span>
                </div>
                : null}
        </div>
    }
}

export default Login