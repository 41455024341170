import React from 'react'
import { trackPromise } from 'react-promise-tracker';
import { BerichtenDetailsWorkingModel, BerichtenFilterWorkingModel } from '../../constants/workingModels';
import { Ordering } from '../../constants/constants';

import { BerichtenDetail, BerichtenGrid, RefreshBar, PagingBar, ModalDialog } from '../organisms';

import * as BerichtenApi from '../../api/berichten';
import * as Actions from './berichten.actions';


class Berichten extends React.Component {

    constructor(props) {
        super(props);

        this.closeModalDialog = this.closeModalDialog.bind(this);

        this.state = {
            pageSize: 25,
            currentPage: 1,
            maxPage: undefined,
            rows: [],
            modal: {
                isOpen: false,
                title: '',
                body: '',
                buttons: [{}],
                redirectLink: false
            },
            berichtInhoud: undefined,
            filterKey: {},
            selectedBerichten: [],
            clearChildState: false,
            orderBy: Ordering.ASC,
            refreshWasClicked: false,
        };
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.rows != null) {
            if (prevState.rows !== this.state.rows || prevState.pageSize !== this.state.pageSize) {
                //Caculate new maxPageSize to pass to organisms/pagingBar
                //We do this here because we need to now how many rows have been gathered via the Api
                let totalRows = this.state.rows.length;
                let newMaxPage = Math.ceil(totalRows / this.state.pageSize);
                //when the currentPage is larger than the pageSize the user selected offers, make it go to the last page
                if (prevState.refreshWasClicked === false && this.state.refreshWasClicked === true){
                    this.setState({currentPage: 1, refreshWasClicked:false});
                }
                else if (this.state.currentPage > newMaxPage && newMaxPage !== 0) {
                    this.setState({ currentPage: parseInt(newMaxPage) });
                }
                this.setState({ maxPage: parseInt(newMaxPage) });
            }
        }
        //Let's catch a change on sorting the table
        if (this.state.orderBy !== prevState.orderBy) {
            //and then reorder the berichten
            let reorderedRows = Actions.orderBerichten(this.state.rows, this.state.orderBy);
            this.setState({ rows: reorderedRows });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.filterKey !== nextState.filterKey) {
            return false
        }
        return true
    }

    selectPageSize = (pageSizeFromPagingBar) => {
        //If another amount of berichten per page has been selected make sure to pass this down to the grid and put it in the state
        this.setState({ pageSize: parseInt(pageSizeFromPagingBar) });
        //and reset selections
        this.setState({ clearChildState: true, berichtInhoud: null });
    };

    selectCurrentPage = (currentPageFromPagingBar) => {
        //If next or previous page has been switched, make sure this is passed down to the grid and put it in the state
        this.setState({ currentPage: parseInt(currentPageFromPagingBar) });
        //and reset selections
        this.setState({ clearChildState: true, berichtInhoud: null });
    }

    sortBerichtenInGrid = (sortDirection) => {
        //If the user has change the sorting in grid by clicking on a row header, we will put the new order in the state
        this.setState({ orderBy: sortDirection });
        //and reset selections
        this.setState({ clearChildState: true, berichtInhoud: null });
    }

    refreshGrid = (buttonClicked) => {
        //Let's get all berichten from the server
        //TrackPromise takes care of showing the loading spinner in case no response has come yet from the backend
        //IF the Api makes us wait for an answer the trackPromise function activates the LoadingSpinner component which is nested in the RefreshBar
        const filter = {
            [BerichtenFilterWorkingModel.StartDateTime]: this.state.filterKey.startDate || null,
            [BerichtenFilterWorkingModel.EndDateTime]: this.state.filterKey.endDate || null,
            [BerichtenFilterWorkingModel.BerichtType]: this.state.filterKey.berichtType || null,
            [BerichtenFilterWorkingModel.StatusVerwerking]: this.state.filterKey.statusVerwerking || null,
            [BerichtenFilterWorkingModel.Bsnnummer]: this.state.filterKey.bsnnummer || null,
            [BerichtenFilterWorkingModel.Onderwijsnummer]: this.state.filterKey.onderwijsnummer || null
        };

        trackPromise(
            BerichtenApi.getBerichten(filter).then(response => {
                if (response != null) {
                    //If we have received a set of berichten from the API put them in the state so BerichtenGrid gets updated
                    //First we pass them trough orderRequestsWithResponses to get the Request and Respones in the right order.
                    let rowsReceived = Actions.orderRequestsWithResponses(response.data);
                    if (rowsReceived == null) {
                        console.error('The getBerichten API did not return an array.');
                        this.showModalDialog(
                            'Foutmelding',
                            'Er is helaas iets mis gegaan bij het ophalen van de berichten. Probeer het nogmaals.'
                        )
                    } else {
                        if (buttonClicked === true){
                            this.setState({ rows: rowsReceived, berichtInhoud: null, clearChildState: true, orderBy: Ordering.ASC, refreshWasClicked:true });
                        }
                        else{
                            this.setState({ rows: rowsReceived, berichtInhoud: null, clearChildState: true, orderBy: Ordering.ASC, });
                        }
                    }
                }
            }).catch(error => {
                if (String(error).includes("code 401")){
                    this.showModalDialog(
                        'Foutmelding',
                        'Uw sessie is verlopen, klik* om opnieuw in te loggen',
                        [{ text: null, trigger: null }],
                        true
                    )
                }
                else{
                    this.showModalDialog(
                        'Foutmelding',
                        'Er is helaas iets mis gegaan bij het ophalen van de berichten. Probeer het nogmaals.'
                    )
                }                
            })
        );
    }

    getBerichtInhoud = (berichtId, StatusVerwerking) => {
        if(berichtId != null) {
        const query = {
            [BerichtenDetailsWorkingModel.Id]: berichtId,
            [BerichtenDetailsWorkingModel.BerichtStatus]: StatusVerwerking
        }

        trackPromise(
            BerichtenApi.getBerichtById(query).then(response => {
                if (response != null) {
                    //If one bericht has been selected get the XML from the server and put it in the state so BerichtenDetails gets updated
                    this.setState({ berichtInhoud: response.data[BerichtenDetailsWorkingModel.BerichtInhoud] });
                }
            }).catch(error => {
                console.log(error);
                this.showModalDialog(
                    'Foutmelding',
                    'Er is helaas iets mis gegaan bij het ophalen van de details. Probeer het nogmaals.'
                )
            })
        )}
        else {
            this.setState({ berichtInhoud: null });
        }
    }

    showModalDialog = (title, body, buttons, redirectLink) => {
        this.setState({
            modal: {
                isOpen: true,
                title: title,
                body: body,
                redirectLink: (redirectLink === undefined || redirectLink === null || redirectLink === false) ? false :true,
                buttons: buttons
            }
        });
        //Makes sure we add the modal-open class to the body so we can disable scroll on the whole document
        document.body.classList.add('modal-open');
    }

    closeModalDialog = () => {
        this.setState({
            modal: {
                isOpen: false,
                title: '',
                body: '',
                buttons: [{}]
            }
        });
        //Makes sure we remove the modal-open class on the body again
        document.body.classList.remove('modal-open');
    }

    deleteBerichten = () => {
        // close confirmation modal dialog
        this.closeModalDialog();
        // Api call to delete messages with query of berichtID's
        const model = this.state.selectedBerichten;
        if (model.length > 0) {
            trackPromise(
                BerichtenApi.deleteBerichten(model).then(response => {
                    if (response.status === 200) {
                        this.showModalDialog(
                            'Success melding',
                            'Bericht(en) zijn succesvol verwijderd.',
                            [{ text: 'Ok', trigger: this.closeModalDialog.bind(this) }]
                        )
                        // delete selectedBerichten in parent and child state and refresh the grid
                        //Also reset the berichtInhoud. There is a chance the delete bericht is currently selected
                        this.setState({ clearChildState: true });
                        this.refreshGrid();
                    }
                }).catch(error => {
                    this.showModalDialog(
                        'Foutmelding',
                        'Er is helaas iets mis gegaan bij het verwijderen van de bericht(en). Probeer het nogmaals.',
                    )
                })
            )
        } else {
            this.showModalDialog(
                'Foutmelding',
                'Er zijn geen berichten geselecteerd.',
                [{ text: 'Ok', trigger: this.closeModalDialog.bind(this) }]
            )
        }
    }

    resendBerichten = () => {
        // close confirmation modal dialog
        this.closeModalDialog();
        // Api call to resend messages with query of berichtID's
        const model = this.state.selectedBerichten;
        if (model.length > 0) {
            trackPromise(
                BerichtenApi.resendBerichten(model).then(response => {
                    //If all berichten can be resend, lets do it!
                    if (response.status === 200) {
                        this.showModalDialog(
                            'Success melding',
                            'Bericht(en) zijn opnieuw verstuurd.',
                            [{ text: 'Ok', trigger: this.closeModalDialog.bind(this) }]
                        )
                        // delete selectedBerichten in parent and child state and refresh the grid
                        this.setState({ clearChildState: true, });
                        this.refreshGrid();
                    }
                    //If one of the berichten is still on the backlog, we cannot resend and cancel the procedure
                    if (response.status === 204) {
                        this.showModalDialog(
                            'Foutmelding',
                            'De geselecteerde berichten konden niet opnieuw worden verstuurd omdat er berichten tussen zitten die nog worden verwerkt.',
                            [{ text: 'Ok', trigger: this.closeModalDialog.bind(this) }]
                        )
                    }
                }).catch(error => {
                    this.showModalDialog(
                        'Foutmelding',
                        'Er is helaas iets mis gegaan bij het verwijderen van de bericht(en). Probeer het nogmaals.',
                    )
                })
            )
        } else {
            this.showModalDialog(
                'Foutmelding',
                'Er zijn geen berichten geselecteerd.',
                [{ text: 'Ok', trigger: this.closeModalDialog.bind(this) }]
            )
        }

    }

    onFilterChange = (filterKey) => {
        this.setState({
            filterKey: Object.assign({}, this.state.filterKey, filterKey)
        });
    }

    onDateChange = (date, button) => {
        if (button === 'startDateButton') {
            this.setState({
                filterKey: Object.assign({}, this.state.filterKey, { startDate: date })
            });
        }
        if (button === 'endDateButton') {
            this.setState({
                filterKey: Object.assign({}, this.state.filterKey, { endDate: date })
            });
        }
    }

    selectedBerichten = (berichtenIDs) => {
        this.setState({
            selectedBerichten: berichtenIDs
        });
    }

    resetClearState = () => {
        this.setState({
            clearChildState: false,
            selectedBerichten: [],
            berichtInhoud: null
        });
    }

    render() {
        return <div id="BerichtenPage">
            <ModalDialog
                modal={this.state.modal}
                closeModalDialog={this.closeModalDialog}
            />
            <RefreshBar refreshGrid={this.refreshGrid} onDateChange={this.onDateChange} />
            <BerichtenGrid
                selectBericht={(berichtId, statusVerwerking) => this.getBerichtInhoud(berichtId, statusVerwerking)}
                changeSorting={(sortDirection) => this.sortBerichtenInGrid(sortDirection)}
                pageSize={this.state.pageSize}
                currentPage={this.state.currentPage}
                rows={this.state.rows}
                onFilterChange={this.onFilterChange}
                selectedBerichten={(berichtenIDs) => this.selectedBerichten(berichtenIDs)}
                clearState={this.state.clearChildState}
                resetClearState={() => this.resetClearState()}
            />
            {this.state.rows.length !== 0 && this.state.currentPage != null && this.state.maxPage != null
                ? <PagingBar
                    selectPageSize={this.selectPageSize}
                    selectCurrentPage={this.selectCurrentPage}
                    currentPage={this.state.currentPage}
                    pageSize={this.state.pageSize}
                    maxPage={this.state.maxPage}
                    showDeleteModal={() => this.showModalDialog(
                        'Berichten verwijderen',
                        'Weet u zeker dat u de geselecteerde berichten wilt verwijderen?',
                        [{ text: 'Verwijderen', trigger: this.deleteBerichten.bind(this) }]
                    )}
                    showResendModal={() => this.showModalDialog(
                        'Berichten opnieuw versturen',
                        'Weet u zeker dat u de geselecteerde berichten opnieuw wilt versturen?',
                        [{ text: 'Opnieuw versturen', trigger: this.resendBerichten.bind(this) }]
                    )}
                />
                : null}
            <hr />

            {this.state.berichtInhoud != null
                ? <BerichtenDetail
                    berichtInhoud={this.state.berichtInhoud}
                />
                : null}
        </div>
    }
}

export default Berichten