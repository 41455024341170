import Axios from 'axios';

Axios.defaults.withCredentials = true;

/**
 * Executes a get 
 * 
 * @param {uriToCall} - the uri to call
 */
export const get = (uriToCall, config = {
    suppressErrors : false
}) => {
    //logger.info(`Get -> ${Axios.defaults.baseURL}${uriToCall}`);

    return Axios.get(uriToCall, config);
};

/**
 * Performs a post to an url
 * 
 * @param {string} uriToCall
 * @param {any} modelToPost
 */
export const post = (uriToCall, modelToPost, config = {
    suppressErrors : false
}) => {
    //logger.info(`Post -> ${Axios.defaults.baseURL}${uriToCall}`, modelToPost);
    return Axios.post(uriToCall, modelToPost, config);
};

/**
 * Performs a put to an url
 * 
 * @param {string} uriToCall
 * @param {any} modelToPut
 */
export const put = (uriToCall, modelToPut, config = {
    suppressErrors : false
}) => {
    //logger.info(`Put -> ${Axios.defaults.baseURL}${uriToCall}`, modelToPut);

    return Axios.put(uriToCall, modelToPut, config);
};

/**
 * Performs a patch to an url
 * 
 * @param {string} uriToCall
 * @param {string} actionToPost
 * @param {any} modelToPost
 */
export const patch = (uriToCall, actionToPatch, modelToPatch, config = {
    suppressErrors : false
}) => {
    //logger.info(`Patch -> ${Axios.defaults.baseURL}${uriToCall}`, modelToPatch);
    //convert model to patch to a patchmodel

    const patchModel =
    {
        Action: actionToPatch,
        Payload: modelToPatch
    };

    return Axios.patch(uriToCall, patchModel, config);
};

/**
 * Performs a delete to an url
 * 
 * @param {string} uriToCall
 */
export const delete_ = (uriToCall, config = {
    suppressErrors : false
}) => {
    //logger.info(`Delete -> ${Axios.defaults.baseURL}${uriToCall}`);

    return Axios.delete(uriToCall, config);
};