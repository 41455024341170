import React from 'react'
import { BerichtenWorkingModel } from '../../constants/workingModels';
import { Ordering, BerichtenWorkingModelExtension } from '../../constants/constants';
import { FaIcon } from '../atoms';

export function orderRequestsWithResponses(rowsReceived) {

    //rowsReceived should be an array of objects. If it's not let throw an error
    if (!Array.isArray(rowsReceived)) {
        console.error('The orderRequestsWithResponses function did not receive an array.');
        return null;
    }

    //Group all berichten on account of their BDID. If two berichten have the same BDID they are now grouped in an array with BDID as key
    let rowsReceivedGrouped = groupByInArray(rowsReceived, BerichtenWorkingModel.BerichtBedrijfsdocumentId);

    //We sort the berichten grouped by BDID on datumOntvangen to get Request berichtas the first item in the array
    Object.keys(rowsReceivedGrouped).forEach((key) => {
        rowsReceivedGrouped[key].sort((a, b) => (a[BerichtenWorkingModel.DatumOntvangen] > b[BerichtenWorkingModel.DatumOntvangen]) ? 1 : ((b[BerichtenWorkingModel.DatumOntvangen] > a[BerichtenWorkingModel.DatumOntvangen]) ? -1 : 0))
    });

    //Let's add a poperty 'children' to the parent (the first in the array) and property 'parent' to the children
    //To do so we will loop through all berichten in rowsReceived
    rowsReceived.forEach((bericht, berichtIndex) => {
        //Since we sorted the rows to have the Request first, we'll make that the parent
        let parent = rowsReceivedGrouped[bericht[BerichtenWorkingModel.BerichtBedrijfsdocumentId]][0];

        //if the berichtId of the parent and berichtId of the loop are the same, we can go and add the children
        if (parent[BerichtenWorkingModel.BerichtId] === bericht[BerichtenWorkingModel.BerichtId]) {
            let allChildren = rowsReceivedGrouped[bericht[BerichtenWorkingModel.BerichtBedrijfsdocumentId]];
            let childrenIdArray = [];
            //Let's loop through all children to be able to exclude the first (which was the parent)
            allChildren.forEach((child, childIndex) => {
                if (childIndex !== 0) {
                    //Add its berichtId to an array
                    childrenIdArray.push(child[BerichtenWorkingModel.BerichtId]);
                }
                if (child[BerichtenWorkingModel.BerichtId] !== bericht[BerichtenWorkingModel.BerichtId]) {
                    //For the child we will add the berichtId of the parent as identifier
                    child[BerichtenWorkingModelExtension.HasRequest] = parent[BerichtenWorkingModel.BerichtId];

                    //First remove the main row of the child because we need to move it under its parent
                    let indexOfChild = rowsReceived.findIndex((x) => { return x[BerichtenWorkingModel.BerichtId] === child[BerichtenWorkingModel.BerichtId] })
                    rowsReceived.splice(indexOfChild, 1);
                    child.icon = <FaIcon icon='level-up-alt' id={`responseOf-${parent[BerichtenWorkingModel.BerichtId]}`} />

                    //And we will add it right next to the parent
                    rowsReceived.splice(berichtIndex, 0, child);
                }
            });
            bericht[BerichtenWorkingModelExtension.HasResponses] = childrenIdArray;
            bericht.icon = <FaIcon icon='envelope' id={`Request-${parent[BerichtenWorkingModel.BerichtId]}`} />
        }
    })

    return rowsReceived;
}

//This function can order an array for a given key
function groupByInArray(givenArray, key) {
    if (Array.isArray(givenArray)) {
        //Return the givenArray after we ordered it
        return givenArray.reduce((orderedArray, perObject) => {
            //Per object we push the full object in a new object with the given key as key-attribute
            (orderedArray[perObject[key]] = orderedArray[perObject[key]] || []).push(perObject);
            return orderedArray;
        }, {});
    }
    //If givenArray is not an array let's return an empty array
    return [];
};


export function orderBerichten(rows, orderDirection) {
    if (orderDirection === Ordering.DESC) {
        rows.sort((a, b) => (a[BerichtenWorkingModel.DatumOntvangen] > b[BerichtenWorkingModel.DatumOntvangen]) ? 1 : ((b[BerichtenWorkingModel.DatumOntvangen] > a[BerichtenWorkingModel.DatumOntvangen]) ? -1 : 0))
    }
    if (orderDirection === Ordering.ASC) {
        rows.sort((a, b) => (a[BerichtenWorkingModel.DatumOntvangen] < b[BerichtenWorkingModel.DatumOntvangen]) ? 1 : ((b[BerichtenWorkingModel.DatumOntvangen] < a[BerichtenWorkingModel.DatumOntvangen]) ? -1 : 0))
    }
    rows.forEach((bericht, berichtIndex) => {
        if (bericht[BerichtenWorkingModelExtension.HasResponses] != null) {
            if (bericht[BerichtenWorkingModelExtension.HasResponses].length) {
                bericht[BerichtenWorkingModelExtension.HasResponses].forEach((child, childIndex) => {

                    //Select the child index and object in the rows to manipulate it
                    let indexOfChild = rows.findIndex((x) => { return x[BerichtenWorkingModel.BerichtId] === child });
                    let childObject = rows[indexOfChild];
                    //First remove the main row of the child because we need to move it under its parent
                    rows.splice(indexOfChild, 1);

                    //And we will add it right next to the parent
                    rows.splice(berichtIndex, 0, childObject);
                });
            }
        }
    });
    return rows;
}