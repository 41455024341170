import * as Helpers from './helpers';
import * as ApiRoutes from './apiRoutes';

/**
 * Send a request to the server to retrieve berichten.
 *
 * @param {any} filter - Optional OData filter.
 * @returns
 */
export const getBerichten = (filter = {}) => {
    return Helpers.post(ApiRoutes.Berichten(), filter, {
        suppressErrors : true,
    });
};

/**
 * Send a request to the server to retrieve a single bericht.
 *
 * @param {any} query - The id of the bericht that needs to be retrieved and the statusVerwerking value.
 * @returns
 */
export const getBerichtById = (query) => {
    return Helpers.post(ApiRoutes.BerichtDetails(), query, {
        suppressErrors: true
    });
};

/**
 * Send a request to the server to delete berichten.
 *
 * @param {any} model - Berichten IDs array.
 * @returns
 */
export const deleteBerichten = (model) => {
    return Helpers.post(ApiRoutes.DeleteBerichten(), model, {
        suppressErrors: true
    });
};

/**
 * Send a request to the server to resend berichten.
 *
 * @param {any} model - Berichten IDs array.
 * @returns
 */
export const resendBerichten = (model) => {
    return Helpers.post(ApiRoutes.ResendBerichten(), model, {
        suppressErrors: true
    });
};