import React from 'react';
import PropTypes from 'prop-types';

/**
 * Base atom for a hyperlink.
 *
 * @param {any} {children, onClick, id, className}
 * @returns
 */
const A = ({ children, href, url, onClick, id, className, title, tabIndex, target, rel }) => {

    const props = {
        id,
        tabIndex,
        onClick,
        className,
        title,
        href: href != null
            ? href
            : url != null
                ? url
                : '#self',
        target,
        rel: target === '_blank' ? 'noopener noreferrer' : rel,
    };

    return (
        <a {...props} >{children}</a>
    );
};

A.propTypes = {
    children: PropTypes.node,
    href: PropTypes.string,
    url: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
    tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    target: PropTypes.string,
    rel: PropTypes.string,
};

export default A;