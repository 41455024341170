import * as Helpers from './helpers';
import * as ApiRoutes from './apiRoutes';

/**
 * Let's the user login to the application
 *
 * @param {any} model - user credentials.
 * @returns
 */
export const loginUser = () => {
    return Helpers.get(ApiRoutes.LoginUser(), {
        suppressErrors: true
    });
};

/**
 * Let's the user logout of the application
 *
 * @param {any} model - user credentials.
 * @returns
 */
export const logoutUser = () => {
    return Helpers.get(ApiRoutes.LogoutUser(), {
        suppressErrors: true
    });
};

export const registerUser = (model) => {
    return Helpers.post(ApiRoutes.RegisterUser(), model, {
        suppressErrors: true
    });
};

export const isAuthenticatedUser = () => {
    return Helpers.get(ApiRoutes.IsAuthenticatedUser(), {
        suppressErrors: true
    });
};