import React from 'react';
import ReactDOM from 'react-dom'

import 'bootstrap/dist/css/bootstrap.min.css';
import style from './index.module.scss'

import * as serviceWorker from './serviceWorker';

//React requires auth to be a defaullt export.
import Auth from './universe/pages/auth';


const routing = (
  <main className={style.adapter}>
    <Auth />
  </main>
)

ReactDOM.render(routing, document.getElementById('root'))


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
