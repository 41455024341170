import React from 'react'


class Logout extends React.Component {

    constructor(props) {
        super(props);
        this.performUserLogout = this.performUserLogout.bind(this);
    };

    performUserLogout = () => {
        this.props.performLogout();
    }

    componentDidMount(){
        this.performUserLogout()
    }

    render() {
        return <h1>Aan het uitloggen..</h1>
    }
}

export default Logout