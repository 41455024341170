import React from 'react'

import { Route, Redirect, Switch, BrowserRouter as Router } from 'react-router-dom'
import { Berichten, NotFound } from './index'
import { Header, Login, Logout } from '../organisms'

import * as UsersApi from '../../api/users';
import { trackPromise } from 'react-promise-tracker';
import { LoadingSpinner } from '../molecules';


class Auth extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            loginError: null,
            isLoading: false
        };
    };


    componentDidMount() {
        //We run an authentication check every time the app is opened
        this.isUserAuthenticated();
    }

    //The authentication method is async. We want to keep isLoading on true as long as the api call takes
    //IMPROVE: There are too many async methods here. isUserAuthenticated(), trackPromise and the api call itself.
    async isUserAuthenticated() {
        this.setState({ isLoading: true })//set isloading to true so we don't show the NotFound page
        await trackPromise(UsersApi.isAuthenticatedUser().then(response => {
            if (response.data === true) {
                //user logged in succesfully. Let's pass him through to the berichten page
                this.setState({ isAuthenticated: true })
            }
        }).catch(error => {
            console.error(error, 'User is not authorized');
        })
        )
        //When the api request has succeeded we can show the pages
        this.setState({ isLoading: false })
    }

    performUserLogout = () => {
        console.log("Logout now");
        UsersApi.logoutUser().then(response => {
            if (response.data === true) {
                //user logged out succesfully. Let's pass him through to the login page (by setting a boolean in the state)
                this.setState({ isAuthenticated: false })
            }
        }).catch(error => {
            console.error(error);
            this.setState({ loginError: 'We kunnen geen verbinding krijgen met de server. Probeer het later nogmaals.' })
        })
    };

    render() {

        if (this.state.isLoading) {
            return <LoadingSpinner />;
        }

        if (this.state.isAuthenticated) {
            return <div>
                <Router id='isAuthenticated'>
                    <Header key="Header" />
                    <main className="col-lg-10 ml-lg-auto mr-lg-auto" key="main">

                        <Switch>
                            <Redirect exact from="/" to="/berichten" />
                            <Redirect exact from="/login" to="/berichten" />
                            <Route path="/berichten" component={Berichten} key="berichten" />
                            <Route path="/logout" render={(props) => <Logout {...props} performLogout={() => this.performUserLogout()} />} key="logout" />
                            <Route
                                key="notfound"
                                children={() =>
                                    <NotFound link={<a href='/berichten'>De berichtenpagina</a>} />}
                            />
                        </Switch>
                    </main>
                </Router>
            </div>
        } else {
            return <Router id='isNotAuthenticated'>
                <Switch>
                    <Redirect exact from="/" to="/login" key="loginRedirect" /> {/*If the user is not authenticated, show login page.*/}
                    <Redirect exact from="/logout" to="/login" key="loginRedirectLogout" /> {/*And have logout redirect to the login page as well as soon as the logout function is performed*/}
                    <Route
                        path="/login"
                        key="login"
                        render={
                            (props) =>
                                <Login {...props} loginError={this.state.loginError} />
                        }
                    />
                    <Route
                        key="notfound"
                        children={() =>
                            <NotFound link={<a href='/login'>De loginpagina</a>} />}
                    />
                </Switch>
            </Router>
        }
    }

}


export default Auth